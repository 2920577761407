import React from 'react';

import fremontImg from './static/imgs/fremontCard.jpg';
import stjohnsImg from './static/imgs/stjohnsCard.jpg';
import jacksonImg from './static/imgs/jacksonCard.jpg';



export default class Shop extends React.Component {
  render() {
    return (
      <div id="shop-content">
        <img id="fremont-card" src={fremontImg} alt="Fremont Greeting Card" />
        <img id="jackson-card" src={jacksonImg}
            alt="Glenn L Jackson Greeting Card" />
        <img id="stjohns-card" src={stjohnsImg} alt="St Johns Greeting Card" />
        <div id="shop-content-txt">
            <div className="shop-heading">Online</div>
            <div id="online-shop-txt" className="shop-content-copy">
              Greeting cards and prints are available on <a href="https://www.etsy.com/shop/DreamingAIByWMDirks">my Etsy store.</a>
            </div>
            <div className="shop-heading">Brick and Mortar</div>
            <div id="brick-and-mortar-shop-txt" className="shop-content-copy">
              Coming soon to stores in Portland!
            </div>
        </div>
      </div>
    );
  }
}
