import React from 'react';

import profileImg from './static/imgs/profile.jpg';

const ABOUT_COPY = "" +
"Bill Dirks is a Portland based artist. He has an Applied Math degree from " +
"Cornell and has worked as a software engineer for over 10 years. Recently, " +
"his interest in neural networks reignited, he started tinkering. " +
"His first project is a tribute to the bridges of Portland. These images were " +
"created using a process known as \"Style Transfer\". Bill has used a neural " +
"network to reimagine photos he has taken in the style of various images he " +
"finds inspiring. He hopes you enjoy viewing them as much as he's enjoyed " +
"creating them!"

export default class About extends React.Component {
  render() {
    return (
      <div id="about-content">
        <img id="profile-img" src={profileImg} alt="Artistic headshot" />
        <div id="about-content-txt">{ABOUT_COPY}</div>
        <div id="email">
          Bill can be reached at <a href="mailto:bill.dirks.art@gmail.com">
            bill.dirks.art@gmail.com</a>.
        </div>
      </div>
    );
  }
}
