import React from 'react';

import broadway from './static/imgs/broadway.jpg';
import fremont from './static/imgs/fremont.jpg';
import hawthorne from './static/imgs/hawthorne.jpg';
import jackson from './static/imgs/jackson.jpg';
import marquam from './static/imgs/marquam.jpg';
import rossIsland from './static/imgs/ross_island.jpg';
import steel from './static/imgs/steel.jpg';
import stjohns from './static/imgs/stjohns.jpg';
import tillikum from './static/imgs/tillikum.jpg';

export default class Gallery extends React.Component {
  render() {
    return (
      <div id="gallery-content">
        <div id="bridge-title">Portland's Bridges</div>
        <div id="intro-text">
          Portland is situated on the Willamette River. A series of bridges connects the downtown to the east side. To the north, the Columbia river separates it from Washington State. This collection depicts 8 of the bridges spanning the Willamette and one bonus image of a bridge crossing the Columbia.
        </div>
        <div id="fremont-bridge" className="bridge">
          <div className="bridge-seperator"></div>
          <div className="bridge-name">Fremont Bridge</div>
          <img id="fremont-img" src={fremont} className="gallery-img"
              alt="Fremont Bridge" />
        </div>
        <div id="stjohns-bridge" className="bridge">
          <div className="bridge-seperator"></div>
          <div className="bridge-name">St Johns Bridge</div>
          <img id="stjohns-img" src={stjohns} className="gallery-img"
              alt="St Johns Bridge" />
        </div>
        <div id="marquam-bridge" className="bridge">
          <div className="bridge-seperator"></div>
          <div className="bridge-name">Marquam Bridge</div>
          <img id="marquam-img" src={marquam} className="gallery-img"
              alt="Marquam Bridge" />
        </div>
        <div id="steel-bridge" className="bridge">
          <div className="bridge-seperator"></div>
          <div className="bridge-name">Steel Bridge</div>
          <img id="steel-img" src={steel} className="gallery-img"
              alt="Steel Bridge" />
       </div>
        <div id="ross-island-bridge" className="bridge">
          <div className="bridge-seperator"></div>
          <div className="bridge-name">Ross Island Bridge</div>
          <img id="rossIsland-img" src={rossIsland} className="gallery-img"
              alt="Ross Island Bridge" />
        </div>
        <div id="jackson-bridge" className="bridge">
          <div className="bridge-seperator"></div>
          <div className="bridge-name">Glenn L. Jackson Memorial Bridge</div>
          <img id="jackson-img" src={jackson} className="gallery-img"
              alt="Jackson Bridge" />
        </div>
        <div id="broadway-bridge" className="bridge">
          <div className="bridge-seperator"></div>
          <div className="bridge-name">Broadway Bridge</div>
          <img id="broadway-img" src={broadway} className="gallery-img"
              alt="Broadway Bridge" />
        </div>
        <div id="hawthorne-bridge" className="bridge">
          <div className="bridge-seperator"></div>
          <div className="bridge-name">Hawthorne Bridge</div>
          <img id="hawthorne-img" src={hawthorne} className="gallery-img"
              alt="Hawthorne Bridge" />
        </div>
        <div id="tillikum-crossing" className="bridge">
          <div className="bridge-seperator"></div>
          <div className="bridge-name">Tillikum Crossing</div>
          <img id="tillikum-img" src={tillikum} className="gallery-img"
              alt="Tillikum Crossing" />
        </div>
      </div>
    );
  }
}
