import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Link, Redirect, Route } from
  "react-router-dom";

import './index.css';
import About from './aboutComponent.js';
import Gallery from './galleryComponent.js';
import Shop from './shopComponent.js';

class TitleBar extends React.Component {
  render() {
    return (
      <div id="title-bar">
        Dreaming AI
      </div>
    );
  }
}

class MenuItem extends React.Component {
  render() {
    const prefix = (this.props.selected === "selected" ? "active-" : "");
    return (
      <Link
        id={this.props.name + "-mi"}
        className={prefix + "menu-item"}
        to={"/" + this.props.name}>
        {this.props.name}
      </Link>
    );
  }
}

class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuBarItems: props.menuBarItems
    };
  }

  menu() {
    return this.state.menuBarItems.map((item) => {
      const selected = item === this.props.selectedMenuItem ? "selected" : "";
      return (
        <MenuItem
           name={item}
           key={item}
           selected={selected} />
      );
    });
  }

  render() {
    return (
      <div id="menu-bar">
        <div id="menu-items">
          {this.menu()}
        </div>
      </div>
    );
  }
}

class Content extends React.Component {
  render() {
    // ContentElement is capitalized so will work in JSX
    const ContentElement = this.props.menuToContent[this.props.selectedMenuItem]
    return (
      <ContentElement />
    );
  };
}

class DreamingAi extends React.Component {
  constructor(props) {
    super(props);
    const menuToContent = {
      Gallery: Gallery,
      About: About,
      Shop: Shop
    };
    const menuItems = Object.keys(menuToContent);
    this.state = {
      menuToContent: menuToContent,
      menuBarItems: menuItems,
    };
  }

  render() {
    return (
      <div id="dreaming-site">
        <TitleBar />
        <Route path="" render={(props) =>
          <MenuAndContent menuBarItems={this.state.menuBarItems}
                          menuToContent={this.state.menuToContent}
                          {...props} />} />
      </div>
    );
  }
}

class MenuAndContent extends React.Component {
  render() {
    const path = this.props.location.pathname;
    const selectedMenuItem = path.charAt(0) === '/' ? path.substr(1) : path;
    if (!(selectedMenuItem in this.props.menuToContent)) {
      return (<Redirect to="/Gallery" />);
    } else {
      return ([
          <MenuBar menuBarItems={this.props.menuBarItems}
                   selectedMenuItem={selectedMenuItem}
                   key="menubar" />,
          <Content menuToContent={this.props.menuToContent}
                   selectedMenuItem={selectedMenuItem}
                   key="content" />
      ]);
    }
  }
}

ReactDOM.render(
  <Router>
    <DreamingAi />
  </Router>,
  document.getElementById('root')
);
